import {Injectable, Injector} from '@angular/core';
import {of, tap} from 'rxjs';
import {ErrorCode} from 'src/app/enums/core/error-code.enum';
import {State} from 'src/app/enums/core/state.enum';
import {UnitsFacade} from 'src/app/facades/units.facade';
import {Page} from 'src/app/interfaces/backend/core/page.interface';
import {Pageable} from 'src/app/interfaces/backend/core/pageable.interface';
import {RadFileDto} from 'src/app/interfaces/backend/dto/rad-file-dto.intrerface';
import {FileUploadEvent} from 'src/app/interfaces/core/file-upload-event.interface';
import {FileUploadResult} from 'src/app/interfaces/core/file-upload-result.interface';
import {RadiologyControllerService} from 'src/app/services/backend/radiology-controller.service';
import {downloadFileFromBase64} from 'src/app/utilities/file.util';
import {displayError} from 'src/app/utilities/toast.util';

@Injectable({providedIn: 'root'})
export class UnitFilesFacade {
    public dataPage: Page<RadFileDto> | null = null;
    public activeItem: RadFileDto | null = null;

    public constructor(
        private radiologyControllerService: RadiologyControllerService,
        private unitsFacade: UnitsFacade,
        private injector: Injector
    ) {
    }

    public reset() {
        this.dataPage = null;
        this.activeItem = null;
    }

    public loadData(pageable: Pageable = {}) {
        this.loadDataLogic(pageable)?.subscribe();
    }

    public loadDataLogic(pageable: Pageable = {}) {
        const fn = (unitId: number) => this.getData(pageable, unitId).pipe(tap(files => this.dataPage = files));
        return this.unitsFacade.getItemId(fn);
    }

    public getData(pageable: Pageable, unitId?: number) {
        return this.radiologyControllerService.getFiles(pageable, unitId);
    }

    public deleteFile(fileId: number, callback?: () => void) {
        this.radiologyControllerService.deleteFile(fileId).subscribe(() => callback?.());
    }

    public getFile(uploadSession: string) {
        const id = uploadSession.toString();
        this.radiologyControllerService.openRadiologyTaskRelatedFileInNewTab(id).subscribe((data) => {
            const {content, extension, fileName} = data;
            if (!content || !extension) {
                displayError(this.injector, ErrorCode.MISSING_REQUEST_DATA);
                return;
            }

            downloadFileFromBase64(content, `.${extension}`, fileName);
        });
    }

    public uploadUnitFile(fileUploadEvent: FileUploadEvent, radUnitId?: number) {
        const {file, fileName} = fileUploadEvent;
        if (!file || !radUnitId) {
            const error: FileUploadResult = {
                state: State.ERROR,
                message: 'No ' + (!radUnitId ? 'unitId' : 'file') + ' for file: ' + fileName
            };
            return of(error);
        }

        return this.radiologyControllerService.uploadUnitFile(radUnitId, file);
    }
}